<template>
  <div>
    <!-- <div
      style="width=40px;height:40px;backgroudcolor:yellow"
      @click="receivedAddValidUser"
    >
      点我测试通知格式
    </div> -->

    <!-- 一个模板中只能有一个div -->
    <!-- <div @click="test">hello</div> -->
    <!-- <div><h1>查看球馆摄像头情况</h1></div> -->
    <!-- 要在el-select 上加方法，而不是在option -->
    <!-- <el-button type="primary" @click="test3434">主要按钮</el-button> -->

    <!-- 不再需要 -->
    <!-- <el-select
      style="margin-top: 30px"
      v-model="value"
      placeholder="请选择球馆"
      @change="onSelect"
    >
      <el-option
        v-for="item in arenas"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-button @click="websocketTest">kaishi</el-button> -->

    <div class="tips">
      <div>
        <ul class="ul-item">
          1.以下场地图片约半小时更新一次
        </ul>
        <ul class="ul-item">
          2.如果图片中篮框不在红框内，可以在日常维护群询问
        </ul>
        <ul class="ul-item">
          3.如果显示半场离线，可以在日常维护群询问
        </ul>
      </div>
    </div>
    <!-- 这块一定要指定高度 否则会过小 -->
    <div class="each_courtshot" v-for="item in courtshots" :key="item.id">
      <!-- @click="fuck($event, item.id)"  不再需要，点击事件，并且传值 -->
      <el-row>
        <el-col :span="24">
          <div
            class="grid-content"
            :class="item.tag === 'online' ? 'online' : 'offline'"
          >
            <!-- 名字部分 -->
          
                        <div class="halfcourt_serial">
              {{ item.halfcourt_serial || item.serial }}
            </div>
            <div
              class="tags"
              :style="{
                backgroundColor: item.tag === 'online' ? '#67C23A' : '#F56C6C',
              }"
            >
              {{ item.tag==='online'?'在线':'离线' }}
            </div>
           
  
            <div class="time">{{ item.created_at }}</div>
            <!-- <div>{{item.images===undefined}}</div> -->
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div
            class="image_arena"
            style="height: 260px"
            v-if="item.images !== undefined"
          >
            <!-- v-if="select_courtshot === item.id"  不再需要-->
            <!-- 我是id为{{ item.id }} 我被点击了 -->
            <div
              class="iamge_arena"
              v-for="iamge in item.images"
              :key="iamge.index"
            >
              <el-image
                style="margin-left: 10px"
                :src="modifyurl(iamge)"
                fit="scale-down"
              >
              </el-image>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div
      class="ul-item"
      style="margin-top: 50px; display: block; text-align: center"
    >
      Copyright © zheshi. Current version is 1.0.0
    </div> -->
    <!-- 级连选择器  看起来应该用不到-->
    <!-- <div class="block">
  <span class="demonstration">hover 触发子菜单</span>
  <el-cascader
    v-model="value"
    :options="options"
    :props="{ expandTrigger: 'hover' }"
    @change="handleChange"></el-cascader>
</div> -->
  </div>
</template>

<script>
// import createSocket from '/api/websocket'
export default {
  name: "HalfCourt",
  data() {
    return {
      image_urls: [],
      arenas: {},
      courtshots: [],
      value: "",
      select_courtshot: 0,
      ishow: false,
      arena_id: 0,
      msg: "我没有收到消息",
    };
  },
  methods: {
    test3434: function () {
      console.log("我在half_workout组件中被点击");
      this.$emit();
    },
    websocketTest: function () {
      // var ws = new WebSocket("192.168.96.84:6001");
      // ws.onopen = function() {
      //   console.log("Connection open ...");
      //   ws.send("Hello WebSockets!");
      // };
      // ws.close()
    },

    //不需要点击
    // fuck(event, item) {
    //   console.log("我被点击了");
    //   console.log(event);
    //   console.log(item);
    //   this.select_courtshot = item;
    // },
    modifyurl: function (url) {
      return "https://obs.zheshi.tech/" + url;
    },

    getArenas: function () {
   
      this.axios
        // .get(`https://zc.open.zheshi.tech/api/v1/rucker/arenas`)
        .get(`https://third.test/api/v1/rucker/arenas`)
        .then((res) => {
          this.arenas = res.data;
          this.arenas.unshift({ id: null, name: "全部场馆" });
          if (sessionStorage.getItem("arena_id") !== null) {
            this.axios
              .get(
                `https://zc.open.zheshi.tech/api/v1/rucker/arena/${sessionStorage.getItem(
                  "arena_id"
                )}/courtshots`
              )
              .then((res) => {           
                this.courtshots = res.data;
                // this.courtshots =sessionStorage.getItem('courtshots')?? res.data;
                // if(sessionStorage.getItem('courtshots'))
                //当前时间半个小时之前的时间戳
                let now = this.$moment().add(-30, "minutes").format("x");
                this.courtshots.forEach((element) => {
                  // console.log(element.created_at >= now);
                  if (element.created_at >= now) {
                    element.tag = "online";
                  } else {
                    element.tag = "offline";
                  }
                  element.created_at = this.$moment(element.created_at).format(
                    "YYYY-MM-DD H:m:s"
                  );
                });
                // sessionStorage.setItem('courtshots',JSON.stringify(res.data))
              });
            // this.courtshots=JSON.parse(sessionStorage.getItem('courtshots'))
            // this.getCourtShots()
          }
        });
    },
    onSelect: function (value) {
      // console.log(value)
      // 现在缓存中设置
      sessionStorage.setItem("arena_id", value);

      this.arena_id = sessionStorage.getItem("arena_id") ?? value;

      this.axios
        .get(
          // `https://zc.open.zheshi.tech/api/v1/rucker/arena/${this.arena_id}/courtshots`
          // `https://zc.open.zheshi.tech/api/v1/rucker/arena/courtshots`
          "http://third.test/api/v1/rucker/arena/courtshots",
          {
            params: {
              arena_id: this.arena_id,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);

          this.courtshots = res.data;
          console.log("*************");
          console.log(res.data);
          // this.courtshots =sessionStorage.getItem('courtshots')?? res.data;
          // if(sessionStorage.getItem('courtshots'))
          //当前时间半个小时之前的时间戳
          let now = this.$moment().add(-30, "minutes").format("x");
          this.courtshots.forEach((element) => {
            // console.log(element.created_at >= now);
            if (element.created_at >= now) {
              element.tag = "在线";
            } else {
              element.tag = "离线";
            }
            element.created_at = this.$moment(element.created_at).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          });

          console.log(this.courtshots);
          // sessionStorage.setItem('courtshots',JSON.stringify(res.data))
        });
    },

    getCourtshot: function (arena_id) {
      this.axios
        .get(
          // `https://zc.open.zheshi.tech/api/v1/rucker/arena/${this.arena_id}/courtshots`
          // `https://zc.open.zheshi.tech/api/v1/rucker/arena/courtshots`
          "https://zc.open.zheshi.tech/api/v1/rucker/arena/courtshots",
          {
            params: {
              arena_id: arena_id,
            },
          }
        )
        //3767440
        .then((res) => {
          this.courtshots = res.data.courtshot;
          // this.courtshots =sessionStorage.getItem('courtshots')?? res.data;
          // if(sessionStorage.getItem('courtshots'))
          //当前时间半个小时之前的时间戳
          let now = this.$moment().add(-30, "minutes").format("x");
          this.courtshots.forEach((element) => {
            // console.log(element.created_at >= now);
            if (element.created_at >= now) {
              element.tag = "online";
            } else {
              element.tag = "offline";
            }
            element.created_at = this.$moment(element.created_at).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          });

          // sessionStorage.setItem('courtshots',JSON.stringify(res.data))
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  // getCourtShots:  function(arena_id){

  // },

  //发请求函数的使用
  // vue 中相互调用函数

  watch: {
    $route(newVal) {
      this.getCourtshot(newVal.query.arena_id);
    },
  },



  mounted() {
    let arena_id = this.$route && this.$route.query.arena_id;
    // 如果不存在，要取缓存中拿，不带路由可要正常进入这个界面
    this.getCourtshot(arena_id);
    // this.getCourtshot();
    // this.getArenas();
    // window.Echo.listen("RuckerValidUser",(e)=>{
    //   console.log(e)
    // });
  },
};
</script>

<style scoped>
.tips {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 12px;
  color: #909399;
  text-align: left;
  width: 300px;
  /* background-color: yellow; */
  height: 100px;
  margin: 0 auto;
  margin-top: 40px;
  /* line-height: 100px; */
}
.each_courtshot {
  background-color: #dcdfe6;
  margin-top: 10px;
  border: 1px solid;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.ul-item {
  font-style: italic;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: small;
}
.el-row {
  margin-bottom: 20px;
  /* margin-top: 60px; */
  /* &:last-child {
      margin-bottom: 0;
    } */
}
.el-col {
  border-radius: 4px;
}
.online {
  background: #67c23a;
}
.offline {
  background-color: #f56c6c;
}
.grid-content {
  width: 100%;
  border-radius: 4px;
  min-height: 36px;
  height: 60px;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 60px; */
}

/* .el-image{
  padding-top: 0px;
  background-color:red;
  
} */

.image_arena {
  display: flex;
  align-items: center;
}

.halfcourt_serial {
  /* height: 100px; */
  /* background-color: #409eff; */
  width: 20%;

  text-align: center;
  height: 100%;

  /* line-height: 100%; */
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #f4f4f4;
}
.iamge_arena {
  display: flex;
  height: 100px;
  height: 260px;
}
.tags {
  width: 50px;
  height: 100%;
  /* height: 100px; */
  text-align: center;
  /* line-height: 100px; */
  /* color: antiquewhite; */
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #f4f4f4;
}

.time {
  /* height: 100px; */
  width: 200px;
  text-align: center;
  /* line-height: 100px; */
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #f4f4f4;
  font-size: 20px;

  /* border: 2px solid black; */
  /* line-height: 100px; */
}
</style>