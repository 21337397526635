
<template>
  <div>
    <div>
      <div class="brand">
        <img class="brand_logo" src="https://obs.zheshi.tech/brand/logo/rucker.png" alt="" />
        <div class="brand_name">洛克</div>
      </div>

      <div class="content">
        <div class="nav">
          <el-col :span="34">
            <el-menu default-active="/summary" class="el-menu-vertical-demo" @select="handleSelect"
              background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" router>
              <el-menu-item index="/summary">
                <i class="el-icon-basketball"></i>
                <span slot="title">信息总览</span>
              </el-menu-item>
              <el-submenu index="/halfcourt">
                <template slot="title">
                  <i class="el-icon-basketball"></i>
                  <span>各场馆设备运行详情</span>
                </template>
                <el-menu-item v-for="(arena, index) in arenas" :key="index" :index="arena.id.toString()"
                  :route="{ path: arenas.url, query: { arena_id: arena.id } }">{{ arena.name }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="/new_users_per_day">
                <i class="el-icon-basketball"></i>
                <span slot="title">每日新增用户和视频</span>
              </el-menu-item>
              <el-menu-item index="/phone_query">
                <i class="el-icon-phone"></i>
                <span slot="title">查找手机号对应的运动记录</span>
              </el-menu-item>
              <el-menu-item index="4" disabled>
                <i class="el-icon-lock"></i>
                <span slot="title">新功能 敬请期待</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </div>
        <div class="component">
          <router-view class="view"> </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echo from "laravel-echo";
export default {
  name: "ArenaInfo",
  data() {
    return {
      arenas: {},
      notified_list: new Set(),
    };
  },
  methods: {
    handleSelect() {
      // console.log(key);
      //   this.$router.push({
      //     path: "/halfcourt",
      //     query: {
      //       arena_id: key,
      //     },
      //   });
    },
    /*
      TODO:应该把所有arena 的name ,去掉前面的洛克公园
      TODO:根据场馆的经纬度，结合地图的api ,判断行政区
      TODO:后台权限认证系统
      TODO:设备上线通知 设备离线通知：需要依靠后端来进行操作，前端不能进行任何处理
      TODO：点击总览页的每个场馆，可以进行跳转
      TODO：有效用户总数和生成视频数，实时滚动跳转（加动画效果）
      */

    getArenas: function () {
      this.axios
        // .get(`https://zc.open.zheshi.tech/api/v1/rucker/arenas`)
        .get(`https://zc.open.zheshi.tech/api/v1/rucker/arenas`)
        .then((res) => {
          this.$store.commit("setArena", res.data)
          this.arenas = res.data.filter((item) => {
            if (item.valid_halfcourt_num === 0) {
              return false;
            }
            return true;
          });

          this.arenas.url = "/halfcourt";
        });
    },
    initWebsocket: function () {
      window.Pusher = require("pusher-js");
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: "zheshi",
        wsHost: "124.71.181.202",
        wsPort: 80,
        forceTLS: false,
        disableStats: true,
        enabledTransports: ["ws"],
      });

      // enabledTransports: ["ws","wss"], 不知道这样行不行
    },
    //通过websocket 收到的用户新增说明   写道全局上
    receivedAddValidUser: function () {
      //向summary  发送事件，
      // this.$store.commit("addValidUserEvent");
      // console.log("我在receivedAddValidUser调用了")
      this.$notify({
        title: "新增了一个有效用户",
        message: "新增了一个有效用户",
        type: "success",
      });
    },

    receivedGenerateVideoEvent: function () {
      this.$notify({
        title: "生成视频数增加",
        // message: "新增了一个有效用户",
        type: "success",
      });
    },
    receivedHalfcourtOff: function (serial) {
      this.$notify({
        title: serial + " 已经下线",
      });
    },
    receivedHalfcourtOn: function (serial) {
      this.$notify({
        title: serial + " 重新上线",
      });
    },

    //进入页面进行数据概览
    startInfo: function () {
      //先从缓存中拿新生成的视频，根据wokrout_id 进行筛选
      this.$notify({
        title: "数据概览",
        message: "在您离开的这段时间内，者识系统为您运行了X次，生成了Y个视频",
        type: "info",
      });
    },

    //用户重开的网页的时候，如果数据不一样，则进行更新,
    //新增用户数如果变化，则有，没变化的话，则提示没有
    diffInfo: function () {
      this.$notify({
        title: "数据更新",
        message: "在您离开的这段时间，新增有效用户数X个，生成视频总数新增Y个",
        type: "success",
      });
    },

    getArenas_bk: function () {
      // let that = this;
      this.axios
        // .get(`https://zc.open.zheshi.tech/api/v1/rucker/arenas`)
        .get(`https://third.test/api/v1/rucker/arenas`)
        .then((res) => {
          this.arenas = res.data;
          //   this.arenas.unshift({ id: null, name: "全部场馆" });
          // console.log("arena_信息");
          // console.log(this.arenas);
          // console.log(res.data)
          if (sessionStorage.getItem("arena_id") !== null) {
            this.axios
              .get(
                `https://zc.open.zheshi.tech/api/v1/rucker/arena/${sessionStorage.getItem(
                  "arena_id"
                )}/courtshots`
              )
              .then((res) => {
                // console.log(res.data);

                this.courtshots = res.data;
                // this.courtshots =sessionStorage.getItem('courtshots')?? res.data;
                // if(sessionStorage.getItem('courtshots'))
                //当前时间半个小时之前的时间戳
                let now = this.$moment().add(-30, "minutes").format("x");
                this.courtshots.forEach((element) => {
                  // console.log(element.created_at >= now);
                  if (element.created_at >= now) {
                    element.tag = "在线";
                  } else {
                    element.tag = "离线";
                  }
                  element.created_at = this.$moment(element.created_at).format(
                    "YYYY-MM-DD H:m:s"
                  );
                });

                // console.log("courtshot 信息");
                // console.log(this.courtshots);
                // sessionStorage.setItem('courtshots',JSON.stringify(res.data))
              });
            // this.courtshots=JSON.parse(sessionStorage.getItem('courtshots'))
            // this.getCourtShots()
          }
        });
    },
  },
  mounted() {
    this.initWebsocket();

    //显示信息的
    // this.diffInfo();
    window.Echo.channel("RuckerChannel").listen(
      "RuckerAddValidUserEvent",
      () => {
        //进行消息弹窗
        this.receivedAddValidUser();

        //发送增加有效用户的事件
        this.$bus.$emit("addValidUser", 777);
      }
    );
    window.Echo.channel("RuckerChannel").listen(
      "RuckerGenerateVideoEvent",
      () => {
        this.receivedGenerateVideoEvent();

        this.$bus.$emit("addGenerateVideo", 666);
      }
    );

    window.Echo.channel("RuckerChannel").listen(
      "RuckerCourtshotOffline",
      (e) => {
        if (e.msg == "offline") {
          if (!this.notified_list.has(e.halfcourtSerial)) {
            this.notified_list.add(e.halfcourtSerial);
            if (this.notified_list.size() !== 0) {
              this.$bus.$emit("courtshotOff", e.halfcourtSerial);
              this.receivedHalfcourtOff(e.halfcourtSerial);
            }
          }

          // console.log(e.halfcourtSerial + "已经下线");
          //第一次有下线，不进行任何通知
          // this.$bus.$emit("courtshotOff", 666);
        } else {
          // console.log(e.halfcourtSerial + "已经重新上线");
          this.$bus.$emit("courtshotOn", e.halfcourtSerial);
          this.receivedHalfcourtOn(e.halfcourtSerial);
        }
      }
    );
    this.getArenas();
    this.$router.push({
      path: "/summary",
    });
  },
};
</script>

<style lang="css">
.view {
  /* background-color:red; */
  width: 100%;
  height: 100%;
}

.nav {
  /* height: 100%;不指定高度，则可以被子元素主动撑开 */
  background-color: #545c64;
  font-family: Arial, Helvetica, sans-serif;
}

.content {
  width: 100%;
  /* height: 90vh; 不指定高度，则可以被子元素主动撑开*/
  /* max-height: 1000px; */
  background-color: wheat;
  display: flex;
  min-height: 100vh;
}

.component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.backGroundImg {
  position: fixed;
  z-index: -1;
}

img {
  overflow: hidden;
  height: auto;
  width: auto\9;
  width: 100%;
}

.tac {
  width: 60%;
}

.brand {
  height: 10vh;
  background-color: #545c64;
  display: flex;
  align-items: center;
}

.brand_name {
  margin-left: 20px;
  font-size: 34px;
  color: #f4f4ff;
}

.brand_logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 50px;
}

body {
  margin: 0;
}
</style>