<template>
    <div>
        <!-- 这个应该是收到websocket 通知进行加一 -->
        <div class="title_arena">
            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    有效用户总数
                    <div class="websocket_num">
                        <countTo :startVal="validUserNumstartVal" :endVal="validUserNumendVal" :duration="3000"></countTo>
                    </div>
                </div>
            </el-card>

            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    今日新增用户数
                    <div class="websocket_num">
                        <countTo :startVal="todayUserNumStartVal" :endVal="todayUserNumEndVal" :duration="3000"></countTo>
                    </div>
                </div>
            </el-card>

            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    生成视频总数
                    <div class="websocket_num">
                        <countTo :startVal="generateVideoNumStartVal" :endVal="generateVideoNumendVal" :duration="3000">
                        </countTo>
                    </div>
                </div>
            </el-card>
        </div>

        <div class="title_arena">
            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    覆盖场馆数
                    <div class="websocket_num" style="color: #409eff">
                        <countTo :startVal="startVal" :endVal="arena_sum" :duration="3000"></countTo>
                    </div>
                </div>
            </el-card>

            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    正常运行系统总数
                    <div class="websocket_num" style="color: #67c23a">
                        <countTo :startVal="alivehalfCourtStartVal" :endVal="alivehalfCourtEndVal" :duration="3000">
                        </countTo>
                    </div>
                </div>
            </el-card>
            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    故障系统总数
                    <div class="websocket_num" style="color: #f56c6c">
                        <countTo :startVal="deadhalfcourtStartVal" :endVal="deadhalfcourtEndVal" :duration="3000"></countTo>
                    </div>
                </div>
            </el-card>
        </div>
        <div class="title_arena">
            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    累计注册用户数
                    <div class="websocket_num" style="color: #409eff">
                        <countTo :startVal="signupUserStartVal" :endVal="signupUserEndVal" :duration="3000"></countTo>
                    </div>
                </div>
            </el-card>
            <el-card :body-style="{}" style="margin-top: 0px; flex: 1">
                <div class="title">
                    今日注册用户数
                    <div class="websocket_num" style="color: #409eff">
                        <countTo :startVal="todaySignupUserStartVal" :endVal="todaySignupUserEndVal" :duration="3000">
                        </countTo>
                    </div>
                </div>
            </el-card>
        </div>
        <!-- v-for="(arena, index) in arenas" -->
        <!-- :offset="index > 0 ? 2 : 2" -->
        <el-row justify="center">
            <!-- 之前的span值为，8,改成了6 -->
            <el-col :span="4" v-for="arena in arenas" :key="arena.id" :offset="1">
                <!-- elementui 组件的名字其实是类的名字，所以需要用点操作符，不是标签的名字 -->

                <a-popover title="故障半场编号"
                    :visible="arena.dead_halfcourt_num !== 0 && hover_display_id === arena.id && display ? true : false">
                    <template slot="content">
                        <!-- <p>Content</p>
            <p>Content</p> -->
                        <p v-for="serial in arena.dead_halfcourts" :key="serial">
                            {{ serial }}
                        </p>
                    </template>
                    <el-card :body-style="{
                        padding: '0px',
                        backgroundColor: arena.dead_halfcourt_num === 0 ? '#67C23A' : '#F56C6C',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }" @click.native="OnSelectArena(arena.id)" @mouseenter.native="enter(arena.id)"
                        @mouseleave.native="leave">
                        <div style="padding: 14px">
                            <div class="card_part">{{ arena.name }}</div>
                            <!-- <div v-if="arena.alive_halfcourt!==0"> -->
                            <div class="card_part">
                                在线：{{ arena.alive_halfcourt_num }} 离线：{{ arena.dead_halfcourt_num }}
                            </div>
                        </div>

                        <!-- <a-button type="primary">
      Hover me
    </a-button> -->
                    </el-card>
                </a-popover>
                <!-- </el-popover> -->

                <!-- <div
          class="dead_list"
          v-if="
            hover_display_id === arena.id &&
            display &&
            arena.dead_halfcourt_num !== 0
          "
        >
          <div v-for="(item, i) in arena.dead_halfcourts" :key="i">
            {{ item }}
          </div>
        </div> -->

                <!-- <el-button>click 激活</el-button> -->
            </el-col>
        </el-row>
    </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
    name: 'SummaryPannel',
    components: { countTo },
    data() {
        return {
            hover_display_id: -1,
            display: false,
            arena_id: null,
            arenas: {},
            valid_user_num: 0,
            generate_video_num: 0,
            arena_sum: 0,

            startVal: 0,
            endVal: 0,

            validUserNumstartVal: 0,
            validUserNumendVal: 0,

            todayUserNumStartVal: 0,
            todayUserNumEndVal: 0,

            generateVideoNumStartVal: 0,
            generateVideoNumendVal: 0,

            alivehalfCourtStartVal: 0,
            alivehalfCourtEndVal: 0,

            deadhalfcourtStartVal: 0,
            deadhalfcourtEndVal: 0,

            signupUserStartVal: 0,
            signupUserEndVal: 0,

            todaySignupUserStartVal: 0,
            todaySignupUserEndVal: 0
        }
    },

    methods: {
        OnSelectArena: function (arena_id) {
            //   console.log(arena_id);
            this.$router.push({
                path: '/halfcourt',
                query: {
                    arena_id: arena_id
                }
            })
            //点击的时候，跳转到halfcourtshot ,前面页面的名字，也要改，而且在跳转回来的时候，
            //如果知道是通过路由跳转的，监听路由回跳事件
        },
        enter: function (arena_id) {
            // console.log("我开始悬停了", arena_id);
            this.hover_display_id = arena_id
            this.display = true
            // this.display=true
        },
        leave: function () {
            // console.log("我悬停离开了");
            this.display = false
        },
        getArenaState: function () {
            let that = this
            this.axios
                .get(`https://zc.open.zheshi.tech/api/v1/rucker/arena/courtshots`, {
                    params: {
                        arena_id: null
                    }
                })
                .then(res => {
                    that.arenas = res.data.arena.filter(item => {
                        if (item.all_halfcourts.length === 0) {
                            return false
                        }
                        return true
                    })
                    that.arena_sum = that.arenas.length
                    that.validUserNumendVal = res.data.valid_user_num
                    that.todayUserNumEndVal = res.data.today_fresh_user_num
                    that.generateVideoNumendVal = res.data.generate_video_num
                    that.signupUserEndVal = res.data.sign_up_num
                    that.todaySignupUserEndVal = res.data.today_sign_up_num

                    //应该设置缓存 要不加载太慢了

                    // that.alivehalfCourtEndVal

                    that.arenas.forEach(element => {
                        // element["halfcourt"];
                        element.name = element.name.slice(4)
                        that.alivehalfCourtEndVal += element.alive_halfcourt_num
                        that.deadhalfcourtEndVal += element.dead_halfcourt_num
                    })
                })
        },

        //这个是会一直保存的
        updateValidUser: function () {
            this.validUserNumstartVal = this.validUserNumendVal
            this.validUserNumendVal += 1
        },

        updateTodayUser: function () {
            this.todayUserNumStartVal = this.todayUserNumEndVal
            this.todayUserNumEndVal += 1
        },

        updateGenerateVideo: function () {
            this.generateVideoNumStartVal = this.generateVideoNumendVal
            this.generateVideoNumendVal += 1
        },

        notifyOff: function (serial) {
            // //故障总数加一
            // this.deadhalfcourtStartVal = this.deadhalfcourtEndVal;
            // this.deadhalfcourtEndVal += 1;

            // //在线总数减一
            // this.alivehalfCourtStartVal = this.alivehalfCourtEndVal;
            // this.alivehalfCourtEndVal -= 1;

            this.arenas.forEach(arena => {
                if (arena.all_halfcourts.includes(serial)) {
                    //找到对应的哪个场馆
                    if (arena.dead_halfcourts.includes(serial)) {
                        //如果已经知道他死掉了
                    } else {
                        // 故障总数加一
                        this.deadhalfcourtStartVal = this.deadhalfcourtEndVal
                        this.deadhalfcourtEndVal += 1
                        // 在线总数减一
                        this.alivehalfCourtStartVal = this.alivehalfCourtEndVal
                        this.alivehalfCourtEndVal -= 1

                        arena.dead_halfcourt_num += 1
                        arena.alive_halfcourt_num -= 1
                    }
                }
            })
        },

        notifyOn: function (serial) {
            this.arenas.forEach(arena => {
                if (arena.all_halfcourts.includes(serial)) {
                    //故障总数减一
                    this.deadhalfcourtStartVal = this.deadhalfcourtEndVal
                    this.deadhalfcourtEndVal -= 1

                    //在线总数加一
                    this.alivehalfCourtStartVal = this.alivehalfCourtEndVal
                    this.alivehalfCourtEndVal += 1

                    arena.alive_halfcourt_num += 1
                    arena.dead_halfcourt_num -= 1
                }
            })
        }
    },
    mounted() {
        //监听他是从路由回来的
        this.getArenaState()

        //进行数据监听
        this.$bus.$on('addValidUser', () => {
            // console.log("我收到了增加用户的事件",data)
            this.updateValidUser()
            this.updateTodayUser()
        })

        this.$bus.$on('addGenerateVideo', () => {
            this.updateGenerateVideo()
        })

        //收到设备离线的通知
        this.$bus.$on('courtshotOff', serial => {
            this.notifyOff(serial)
        })

        //收到设备重新上线的通知
        this.$bus.$on('courtshotOn', serial => {
            this.notifyOn(serial)
        })
    }
}
</script>

<style scoped>
/* .el-row {
  border: 1px solid black;
}

.el-col {
  border: 1px solid blueviolet;
} */

.websocket_num {
    /* background-color: aqua; */
    height: 100%;
    font-family: 'Digital dream Regular', Courier, monospace;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(223, 25, 25);
    font-weight: 600;
    /* border: 2px solid black; */
}

.title_arena {
    width: 100%;
    height: 10rem;
    /* background-color: cornflowerblue; */
    display: flex;
}

.title {
    /* flex: 1; */
    height: 100%;
    /* flex: 1; 进行平分整个容器 */
    /* width: 260px; */
    /* height: 60px; */
    /* background-color: yellow; */
    /* margin-top: 30px; */
    /* margin: 0 auto; */
    font-size: 30px;
    text-align: center;
    /* line-height: 60px; */
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
}

.time {
    font-size: 13px;
    color: #999;
}

.el-dropdown-link {
    /* cursor: pointer; */
    color: #fff;
}

.card_part {
    /* background-color: #fff; */
    /* border: 2px solid red; */
    text-align: center;
    color: #fff;
    font-weight: 700;
}

.place_hold {
    padding: 14px;
}

.dead_list {
    /* width: 4rem; */
    /* height: 100px; */
    /* width: 100%; */
    text-align: center;
    color: #fff;
    font-weight: 700;
    /* padding: auto; */
    /* padding-left: 24px; */
    padding: 14px;
    /* padding-top: 60px; */
    background-color: #606266;
    position: absolute;
    /* border: 1px solid #cccccc; */
    z-index: 100;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* margin:auto; */
    /* margin-left: 10px; */
    /* display: flex; */
}

.image {
    width: 100%;
    display: block;
}

.el-card {
    margin-top: 30px;
    /* position: relative; */
}
</style>
