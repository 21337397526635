import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Moment from "moment";
import axios from "axios";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
//引入路由器
import router from "./router";
import Vuex from "vuex";
import "ant-design-vue/dist/antd.css";
import { Popover } from "ant-design-vue";
// import store from './store/index'
// 这块需要拆开，多个插件引入
Vue.use(VueAxios, axios);
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Popover);
Vue.config.productionTip = false;
Vue.component(Popover.name, Popover);
Vue.prototype.$moment = Moment;
// Vue.prototype.component_name = "Option"
// Vue.prototype.$axios = axios  不要使用这种方式，会污染Vue

//这块直接在这里写就可以，不需要像路由一样，写个单独的文件夹
const store = new Vuex.Store({
  actions: {}, //actions-- - 用于响应组件中的动作
  mutations: {
    setArena: (state, data) => {
      state.arenas = data;
    },
  }, //准备mutations --- 用于操作数据（state)
  state: {
    arenas: [],
  }, //准备state-- 用于存储数据
});
// Vue.use(ElementUI, axios, VueAxios)
new Vue({
  render: (h) => h(App),
  router: router,
  store: store,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
