<template>
  <div id="father">
    <h4>选择日期</h4>

    <el-col style="margin-bottom: 50px">
      <div id="user_action">
        <el-date-picker v-model="times" value-format="timestamp" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" id="button" :disabled="times.length < 2 || queryDisabled"
          v-on:click="getNewUsersPerDay">
          查询
        </el-button>
      </div>
      <el-row justify="center">
        <el-col :span="4" v-for="arena in    arenas   " :key="arena.id">
          <el-card :body-style="{
            padding: '0px',
            backgroundColor: arena.id === selectedArenaId ? '#67C23A' : '#f4f4ff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }" @click.native="OnSelectArena(arena.id)">
            <div style="padding: 14px">
              <div class="card_part">{{ arena.name }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-table :data="newUsersPeryDay" height="500" style="width: 440px; margin-top: 50px;">
        <el-table-column prop="date" label="日期" width="150">
        </el-table-column>
        <el-table-column prop="num" label="新增用户数" width="120">
        </el-table-column>
        <el-table-column prop="video_num" label="新增视频数" width="120">
        </el-table-column>
      </el-table>
    </el-col>
  </div>
</template>
  
<script >
export default {
  name: "NewUsersPerDay",
  data() {
    return {
      times: [],
      newUsersPeryDay: [],
      selectedArenaId: "",
      queryDisabled: false,
      arenas: this.$store.state.arenas
    };
  },
  mounted() {
  },
  methods: {
    getNewUsersPerDay: function () {
      if (this.times.length < 2) {
        return
      }
      const startTime = this.times[0]
      const endTime = this.times[1] + 24 * 3600 * 1000 - 1000

      let that = this; //this 传递过去
      let queryURL = `https://zc.open.zheshi.tech/api/v1/rucker/fresh-user-num?start=${startTime}&end=${endTime}`
      if (this.selectedArenaId)
        queryURL += `&arena_id=${this.selectedArenaId}`
      this.queryDisabled = true
      this.axios
        .get(queryURL)
        .then(function (res) {
          that.newUsersPeryDay = res.data.daily_fresh_users
        })
        .catch(function () {
          that.newUsersPeryDay = []
        })
        .finally(function () {
          that.queryDisabled = false
        });
    },
    OnSelectArena: function (arena_id) {
      if (this.selectedArenaId === arena_id)
        this.selectedArenaId = ""
      else
        this.selectedArenaId = arena_id
    },
  },
};
</script>
  
<style scoped>
.uu {
  background-color: aqua;
}

.el-image {
  filter: blur(10px);
}

.pimage {
  margin-top: 30px;
  margin-left: 40px;
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.el-button--primary {
  color: #fff;
  background-color: #6236ff;
  border-color: #6236ff;
  margin-left: 20px;
}

.video_header {
  background-color: yellow;
}

.video_number {
  color: #6236ff;
  font-size: 900;
}

#face_number {
  display: block;
  /* background-color: red; */

  text-align: center;
  margin: 0 auto;
  margin-top: 30px;

  /* margin-left: 50%;
      margin-right: 50%; */
}

.card_part {
  /* background-color: #fff; */
  /* border: 2px solid red; */
  text-align: center;
  color: rgb(3, 3, 3);
  font-weight: 700;
}

#arena_name {
  font-size: 17px;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  /* background-color: yellow; */
}

#workout_item {
  /* background-color:aqua; */
  display: block;
  margin-bottom: 10px;
  width: 300px;
  height: 100px;
  border-left: 4px solid #6236ff;
  display: flex;
  flex-direction: column;
  /* border-radius: 10px; */
}

#index {
  text-align: center;
  justify-content: center;
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #6236ff;
  margin-left: -9px;
  border-radius: 50%;
  margin-top: 28px;
  color: #fff;
  line-height: 18px;
}

#workout_title {
  /* background-color:aqua; */
  margin-left: 20px;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei";
}

#row {
  background-color: #f4f4f4;
  /* border: 2px solid rebeccapurple; */
  display: flex;
  width: auto;
  justify-content: space-between;

  /* height: 60px; */
}

#video_number {
  padding: auto;
  flex: 1;
  background-color: aquamarine;
}

#father {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 30px;
}

#load_image {
  width: inherit;
}

#input_text {
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

#button {
  height: 40px;
}
</style>