<template>
  <arena-info> </arena-info>
</template>

<script>
import ArenaInfo from "./components/ArenaInfo";
export default {
  name: "App",
  components: {
    ArenaInfo,
  },
};

// 可以使用在style 中使用body 标签
</script >

<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
