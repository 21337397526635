import VueRouter from "vue-router";
import Workout from "../components/Workout";
import HalfCourt from "../components/HalfCourt";
import SummaryPannel from "../components/SummaryPannel";
import NewUsersPerDay from "../components/NewUsersPerDay";

const router = new VueRouter({
    routes: [
        {
            path: '/phone_query',
            component:Workout,
        },
        {
            path: '/halfcourt',
            component: HalfCourt,
    
        },
        {
            path: '/summary',
            component:SummaryPannel,
        },
        {
            path: '/new_users_per_day',
            component:NewUsersPerDay,
        },
    ]
})

export default router