<template>
  <div id="father">
    <!-- <el-button type="primary" @click="test">主要按钮</el-button> -->
    <div style="margin-bottom: 30px">
      <ul class="ul-item">
        1.若无人脸照片，请提示用户主动上传人脸照片后，重新进场
      </ul>
      <ul class="ul-item">
        2.若记录时间对应，但视频数量少或没有视频，请联系者识后台
      </ul>
      <ul class="ul-item">
        3.若运动记录时间不对应，或视频数量不为0，但用户无法看到视频，请联系洛克后台
      </ul>
    </div>

    <h4>搜索用户</h4>

    <el-col :span="8" style="margin-bottom: 50px">
      <div id="user_action">
        <el-input id="input_text" type="text" v-model="phone" @keyup.enter="change" placeholder="请输入11位手机号">
        </el-input>
        <el-button type="primary" id="button" v-on:click="change">
          查询</el-button>
      </div>
    </el-col>

    <div style="display: flex" v-if="isloaded">
      用户照片
      <div>共有</div>
      <div style="color: red; font-size: larger">{{ faces.length }}</div>
      <div>张人脸</div>
    </div>

    <div style="display: flex" v-if="isloaded">
      <div class="pimage" v-for="(image_path, index) in    faces   " :key="index"
        :style="{ 'background-image': 'url(' + host + image_path + ')', }">
        <!-- <el-image style="width: 100px; height: 100px" :src="host + image_path"></el-image> -->
        <div class="eye-blur">
        </div>
      </div>
    </div>

    <div id="face_number" style="display: flex"></div>
    <div id="workout_list" v-if="isloaded">
      <div class="ul-item" style="margin-left: -170px">
        *默认显示十条运动记录
      </div>
    </div>

    <el-table :data="workouts" stripe :cell-style="cellStyle" v-if="isloaded" style="width: 100%" highlight-current-row>
      <el-table-column align="center" prop="arena.name" label="场馆" width="180">
      </el-table-column>
      <el-table-column prop="started_at" label="开始时间" width="180" align="center"></el-table-column>
      <el-table-column align="center" prop="ended_at" label="结束时间">
      </el-table-column>
      <el-table-column align="center" prop="video_num" label="视频数量">
      </el-table-column>
    </el-table>

    <!-- <div
      class="ul-item"
      style="margin-top: 50px; display: block; text-align: center"
    >
      Copyright © zheshi. Current version is 1.2.0
    </div> -->
  </div>
  <!-- 这个每次记得加 -->
</template>

<script >
export default {
  name: "Workout",
  data() {
    return {
      faces: [],
      isloaded: false,
      phone: "",
      workouts: [],
      host: "https://obs.zheshi.tech/",
    };
  },
  methods: {
    test: function () {
      console.log("我在workout组件中被点击");
    },
    cellStyle: function ({ columnIndex }) {
      if (columnIndex == 3) {
        return {
          color: "#6236ff",
        };
      }
    },
    change: function () {
      let that = this; //this 传递过去
      this.axios
        .get(`https://zc.open.zheshi.tech/api/v1/phone-front/${this.phone}`)
        // .get(`https://zc.open.zheshi.tech/api/v1/phone-front/13818319317`)
        .then(function (res) {
          // console.log(res.data.workouts);
          res.data.workouts.forEach((element) => {
            element.started_at = that
              .$moment(element.started_at)
              .format("YYYY-MM-DD HH:mm:ss");
            element.ended_at = that
              .$moment(element.ended_at)
              .format("YYYY-MM-DD HH:mm:ss");
          });

          that.workouts = res.data.workouts;
          // console.log(that.workouts);

          // res.data.face_number.map(this.yy)
          that.faces = res.data.face_number;
          // console.log(that.face_number);
          that.isloaded = true;
        })
        .catch(function () {
          that.workouts = [];
          that.face_number = 0;
          that.isloaded = false;
          // console.log("fuck")
        });

      // this.faces.forEach((image_path) => {
      //   iamge_path = "https://obs.zheshi.tech/" + iamge_path;
      // });
    },

    async getData() {
      // // let response = await this.getToken()
      // let that = this
      // // access_token = response.data.access_token
      // if (input.phone === '') {
      //     alert('手机号不能为空！！！')
      // }
      // this.axios.get(`https://zc.open.zheshi.tech/api/v1/phone-front/${input.phone}`, {
      //     // headers: {
      //     //     'Authorization': `Bearer ${access_token}`
      //     // }
      // }).then(function (response) {
      //     json_data = response.data.workouts
      //     app.workouts = json_data
      //     for (let index = 0; index < json_data.length; index++) {
      //         json_data[index].started_at = that.parseTime(json_data[index].started_at)
      //         json_data[index].ended_at = that.parseTime(json_data[index].ended_at)
      //     }
      //     fnum.face_number = response.data.face_number
      //     if (fnum.face_number !== undefined) {
      //         fnum.isloaded = true
      //         app.isloaded = true
      //     }
      //     // 13564090764
      // }).catch(function (response) {
      //     app.workouts = []
      //     fnum.face_number = 0
      //     fnum.isloaded = false
      //     alert('手机号有误！')
      // });
    },
    async getToken() {
      // return new Promise((resolve, reject) => {
      //     this.axios.post('https://zc.open.zheshi.tech/api/v1/auth/tokens', {
      //             appid: 'rucker',
      //             appkey: 'c46e8cca5b5204a38201979760ad865356bb0d9c'
      //         })
      //         .then(function (response) {
      //             // console.log(response.data.access_token);
      //             // access_token = response.data.access_token
      //             resolve(response)
      //         })
      //         .catch(function (error) {
      //             console.log(error);
      //         })
      // })
    },
  },
};
</script>

<style scoped>
.uu {
  background-color: aqua;
}

.el-image {
  /* filter: blur(10px); */
}

.pimage {
  margin-top: 30px;
  margin-left: 10px;
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye-blur {
  background-color: rgb(255 255 255 / 0.3);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 20%;
}

.el-button--primary {
  color: #fff;
  background-color: #6236ff;
  border-color: #6236ff;
}

.video_header {
  /* color: #909399; */
  background-color: yellow;
}

.video_number {
  color: #6236ff;
  font-size: 900;
}

/* 这块要改 */
/* #html,#body{

    height: 100%;
}
#html{
    background-color:yellow;
       display: table;
    margin: auto;
}

#body{
        display: table-cell;
    vertical-align: middle;
} */

#face_number {
  display: block;
  /* background-color: red; */

  text-align: center;
  margin: 0 auto;
  margin-top: 30px;

  /* margin-left: 50%;
    margin-right: 50%; */
}

#workout_list {
  /* background-color: yellow; */
  display: flex;
  width: auto;
  /* height: 700px; */
  flex-wrap: wrap;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
}

.card_part {
  /* background-color: #fff; */
  /* border: 2px solid red; */
  text-align: center;
  color: #fff;
  font-weight: 700;
}

#arena_name {
  font-size: 17px;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  /* background-color: yellow; */
}

#workout_item {
  /* background-color:aqua; */
  display: block;
  margin-bottom: 10px;
  width: 300px;
  height: 100px;
  border-left: 4px solid #6236ff;
  display: flex;
  flex-direction: column;
  /* border-radius: 10px; */
}

#index {
  text-align: center;
  justify-content: center;
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #6236ff;
  margin-left: -9px;
  border-radius: 50%;
  margin-top: 28px;
  color: #fff;
  line-height: 18px;
}

.ul-item {
  font-style: italic;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: small;
}

#workout_title {
  /* background-color:aqua; */
  margin-left: 20px;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei";
}

#row {
  background-color: #f4f4f4;
  /* border: 2px solid rebeccapurple; */
  display: flex;
  width: auto;
  justify-content: space-between;

  /* height: 60px; */
}

#video_number {
  padding: auto;
  flex: 1;
  background-color: aquamarine;
}

#father {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

#load_image {
  width: inherit;
}

#input_text {
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

#button {
  height: 40px;
}

#user_action {
  display: flex;
}
</style>